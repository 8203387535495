@import "https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;600;700&display=swap";
:root {
  --colorWhite: #fff;
  --colorTextSecondary: silver;
  --colorTextDisabled: #87878e;
  --colorHighlight: #ceaef0;
  --colorTextError: #ff5a5a;
  --colorWhiteDisabled: #ffffff4d;
  --colorBorder: #544c58;
  --colorDividerPrimary: #fff3;
  --colorLightGreyPurple: #544f75;
  --colorLightGreyPurpleTranslucent: #544f75a3;
  --colorSurface: #1d1b28;
  --colorSurfacePale: #ffffff29;
  --colorSurfacePale2: #ffffff14;
  --colorSurfaceDisabled: #3e3d48;
  --colorSurfaceDark: #24222f;
  --colorSurfaceError: #683139;
  --modalBackgroundOverlay: #000000a3;
  --colorError: #ff5a5a;
  --colorSuccess: #6fcf97;
  --colorGreen: #6fcf9752;
  --gradient-primary: linear-gradient(135deg, #d22c34 0%, #6920cb 100%);
  --baseFont: "Montserrat", sans-serif;
}

.lNzwXq_button {
  background: var(--gradient-primary);
  border-radius: 24px;
  padding: 12px 24px;
  font-weight: 700;
}

@media only screen and (width >= 768px) {
  .lNzwXq_button {
    width: unset;
  }
}

@media only screen and (width >= 1320px) {
  .lNzwXq_button {
    height: 48px;
  }
}

.lNzwXq_button:hover {
  opacity: .9;
}

.lNzwXq_button:active {
  opacity: .8;
}

.lNzwXq_button:disabled {
  color: var(--colorTextDisabled);
  cursor: not-allowed;
  background: var(--colorSurfaceDisabled) !important;
}

.lNzwXq_secondary {
  border: 1px solid var(--colorWhite);
  background: var(--colorSurfaceDark);
}

.lNzwXq_secondary:hover {
  opacity: .9;
}

.lNzwXq_secondary:active {
  opacity: .8;
}

:root {
  --colorWhite: #fff;
  --colorTextSecondary: silver;
  --colorTextDisabled: #87878e;
  --colorHighlight: #ceaef0;
  --colorTextError: #ff5a5a;
  --colorWhiteDisabled: #ffffff4d;
  --colorBorder: #544c58;
  --colorDividerPrimary: #fff3;
  --colorLightGreyPurple: #544f75;
  --colorLightGreyPurpleTranslucent: #544f75a3;
  --colorSurface: #1d1b28;
  --colorSurfacePale: #ffffff29;
  --colorSurfacePale2: #ffffff14;
  --colorSurfaceDisabled: #3e3d48;
  --colorSurfaceDark: #24222f;
  --colorSurfaceError: #683139;
  --modalBackgroundOverlay: #000000a3;
  --colorError: #ff5a5a;
  --colorSuccess: #6fcf97;
  --colorGreen: #6fcf9752;
  --gradient-primary: linear-gradient(135deg, #d22c34 0%, #6920cb 100%);
  --baseFont: "Montserrat", sans-serif;
}

.P8EkWq_wallet-cta {
  background-color: var(--colorLightGreyPurple);
  border-radius: 24px;
  gap: 8px;
  height: 36px;
  padding: 8px;
  display: flex;
}

@media only screen and (width >= 768px) {
  .P8EkWq_wallet-cta {
    justify-content: space-between;
    align-items: center;
    width: 170px;
    display: flex;
  }
}

@media only screen and (width >= 1320px) {
  .P8EkWq_wallet-cta {
    height: 48px;
  }
}

.P8EkWq_wallet-cta .P8EkWq_icon {
  width: 20px;
  height: 20px;
}

@media only screen and (width >= 1320px) {
  .P8EkWq_wallet-cta .P8EkWq_icon {
    width: 32px;
    height: 32px;
  }
}

.P8EkWq_wallet-cta .P8EkWq_user-address {
  display: none;
}

@media only screen and (width >= 768px) {
  .P8EkWq_wallet-cta .P8EkWq_user-address {
    display: unset;
  }
}

.P8EkWq_disconnect-btn {
  background-color: var(--colorSurfaceDark);
  color: var(--colorHighlight);
  z-index: 1;
  border-radius: 8px;
  align-items: center;
  gap: 16px;
  height: 36px;
  margin-top: 8px;
  margin-right: 32px;
  padding: 0 24px;
  display: flex;
  position: absolute;
  right: 0;
  box-shadow: 0 4px 4px #0000004d, 0 8px 12px 6px #00000026;
}

@media only screen and (width >= 1320px) {
  .P8EkWq_disconnect-btn {
    height: 48px;
  }
}

@media only screen and (width >= 768px) {
  .P8EkWq_disconnect-btn {
    right: unset;
  }
}

.P8EkWq_disconnect-btn .P8EkWq_icon {
  width: 20px;
  height: 20px;
}

.P8EkWq_temporary-div {
  width: 100px;
}

._7XoZHW_navbar {
  z-index: 1;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  width: 100%;
  padding: 8px 0;
  position: sticky;
  top: 0;
}

@media only screen and (width >= 576px) {
  ._7XoZHW_navbar {
    padding: 8px 0;
  }
}

@media only screen and (width >= 768px) {
  ._7XoZHW_navbar {
    padding: 16px 0;
  }
}

@media only screen and (width >= 1320px) {
  ._7XoZHW_navbar {
    padding: 24px 0;
  }
}

._7XoZHW_navbar ._7XoZHW_content {
  justify-content: space-between;
  align-items: center;
  max-width: 328px;
  margin: auto;
  display: flex;
}

@media only screen and (width >= 576px) {
  ._7XoZHW_navbar ._7XoZHW_content {
    max-width: 528px;
  }
}

@media only screen and (width >= 768px) {
  ._7XoZHW_navbar ._7XoZHW_content {
    max-width: 720px;
  }
}

@media only screen and (width >= 1024px) {
  ._7XoZHW_navbar ._7XoZHW_content {
    max-width: 972px;
  }
}

@media only screen and (width >= 1320px) {
  ._7XoZHW_navbar ._7XoZHW_content {
    max-width: 1272px;
  }
}

._7XoZHW_navbar ._7XoZHW_wombat {
  align-items: center;
  gap: 4px;
  display: flex;
}

._7XoZHW_navbar ._7XoZHW_wombat ._7XoZHW_logo {
  width: 20px;
  height: 20px;
}

@media only screen and (width >= 768px) {
  ._7XoZHW_navbar ._7XoZHW_wombat ._7XoZHW_logo {
    width: 24px;
    height: 24px;
  }
}

@media only screen and (width >= 1320px) {
  ._7XoZHW_navbar ._7XoZHW_wombat ._7XoZHW_logo {
    width: 36px;
    height: 36px;
  }
}

._7XoZHW_navbar ._7XoZHW_menu {
  display: none;
}

@media only screen and (width >= 768px) {
  ._7XoZHW_navbar ._7XoZHW_menu {
    justify-content: center;
    align-items: center;
    gap: 24px;
    display: flex;
  }
}

@media only screen and (width >= 1320px) {
  ._7XoZHW_navbar ._7XoZHW_menu {
    gap: 48px;
  }
}

._7XoZHW_navbar ._7XoZHW_nav-link {
  border-radius: 24px;
  padding: 12px 24px;
}

._7XoZHW_navbar ._7XoZHW_nav-link:hover {
  background: #ffffff14;
}

._7XoZHW_navbar ._7XoZHW_nav-link:active {
  background: #ffffff1f;
}

.q33lvW_frame {
  background-image: url("frame-medium.1e4adcc6.svg");
  background-repeat: no-repeat;
  background-size: contain;
  padding: 10px;
}

@media only screen and (width >= 576px) {
  .q33lvW_frame {
    background-image: url("frame-gallery-mobile-md.c13d8888.svg");
  }
}

@media only screen and (width >= 1024px) {
  .q33lvW_frame {
    background-image: url("frame-large.9109029f.svg");
  }
}

.q33lvW_frame-container {
  position: relative;
}

.q33lvW_image-container {
  margin: 11px;
  position: absolute;
  top: 0;
}

:root {
  --colorWhite: #fff;
  --colorTextSecondary: silver;
  --colorTextDisabled: #87878e;
  --colorHighlight: #ceaef0;
  --colorTextError: #ff5a5a;
  --colorWhiteDisabled: #ffffff4d;
  --colorBorder: #544c58;
  --colorDividerPrimary: #fff3;
  --colorLightGreyPurple: #544f75;
  --colorLightGreyPurpleTranslucent: #544f75a3;
  --colorSurface: #1d1b28;
  --colorSurfacePale: #ffffff29;
  --colorSurfacePale2: #ffffff14;
  --colorSurfaceDisabled: #3e3d48;
  --colorSurfaceDark: #24222f;
  --colorSurfaceError: #683139;
  --modalBackgroundOverlay: #000000a3;
  --colorError: #ff5a5a;
  --colorSuccess: #6fcf97;
  --colorGreen: #6fcf9752;
  --gradient-primary: linear-gradient(135deg, #d22c34 0%, #6920cb 100%);
  --baseFont: "Montserrat", sans-serif;
}

.-oZg3q_link {
  justify-content: space-between;
  align-items: center;
  max-width: 280px;
  display: flex;
}

.-oZg3q_default {
  color: var(--colorHighlight);
  text-underline-offset: 5px;
  font-size: 14px;
  font-weight: 400;
  text-decoration: underline;
}

.-oZg3q_button-like--primary {
  background: var(--gradient-primary);
  border-radius: 24px;
  padding: 12px 24px;
  font-weight: 700;
}

.-oZg3q_button-like--primary:hover {
  opacity: .9;
}

.-oZg3q_button-like--primary:active {
  opacity: .8;
}

.-oZg3q_button-like--secondary {
  border: 1px solid var(--colorWhite);
  background: var(--colorSurfaceDark);
  border-radius: 24px;
  padding: 12px 24px;
}

.-oZg3q_button-like--secondary:hover {
  opacity: .9;
}

.-oZg3q_button-like--secondary:active {
  opacity: .8;
}

:root {
  --colorWhite: #fff;
  --colorTextSecondary: silver;
  --colorTextDisabled: #87878e;
  --colorHighlight: #ceaef0;
  --colorTextError: #ff5a5a;
  --colorWhiteDisabled: #ffffff4d;
  --colorBorder: #544c58;
  --colorDividerPrimary: #fff3;
  --colorLightGreyPurple: #544f75;
  --colorLightGreyPurpleTranslucent: #544f75a3;
  --colorSurface: #1d1b28;
  --colorSurfacePale: #ffffff29;
  --colorSurfacePale2: #ffffff14;
  --colorSurfaceDisabled: #3e3d48;
  --colorSurfaceDark: #24222f;
  --colorSurfaceError: #683139;
  --modalBackgroundOverlay: #000000a3;
  --colorError: #ff5a5a;
  --colorSuccess: #6fcf97;
  --colorGreen: #6fcf9752;
  --gradient-primary: linear-gradient(135deg, #d22c34 0%, #6920cb 100%);
  --baseFont: "Montserrat", sans-serif;
}

.nE5dIG_token {
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
  width: 220px;
  min-width: 220px;
  display: flex;
}

@media only screen and (width >= 576px) {
  .nE5dIG_token {
    width: 160px;
    min-width: 160px;
  }
}

@media only screen and (width >= 1320px) {
  .nE5dIG_token {
    width: 220px;
    min-width: 220px;
  }
}

.nE5dIG_token .nE5dIG_image-container {
  align-items: center;
  width: 220px;
  height: 324px;
  display: flex;
  overflow: hidden;
}

@media only screen and (width >= 576px) {
  .nE5dIG_token .nE5dIG_image-container {
    width: 160px;
    height: 234px;
  }
}

@media only screen and (width >= 1320px) {
  .nE5dIG_token .nE5dIG_image-container {
    width: 220px;
    height: 324px;
  }
}

.nE5dIG_token .nE5dIG_image {
  width: 100%;
  display: block;
}

.nE5dIG_token .nE5dIG_top-data {
  justify-content: space-between;
  width: 100%;
  display: flex;
  position: absolute;
}

.nE5dIG_token .nE5dIG_token-id {
  background: var(--colorLightGreyPurpleTranslucent);
  border-radius: 12px;
  margin: 8px;
  padding: 4px 8px;
  font-size: 12px;
  font-weight: 700;
}

.nE5dIG_token .nE5dIG_external-link {
  background: var(--colorLightGreyPurpleTranslucent);
  border-radius: 12px;
  margin: 8px;
  padding: 4px;
}

.nE5dIG_token .nE5dIG_progress__bar-container {
  position: relative;
}

.nE5dIG_token .nE5dIG_progress__bar {
  border-radius: 4px;
  height: 8px;
}

.nE5dIG_token .nE5dIG_progress__bar--max {
  background-color: #ffffff14;
}

.nE5dIG_token .nE5dIG_progress__bar--current {
  z-index: 1;
  background-color: var(--colorSuccess);
  position: absolute;
  top: 0;
}

.nE5dIG_token .nE5dIG_progress__text {
  color: var(--colorTextDisabled);
  font-size: 12px;
  font-weight: 400;
}

.nE5dIG_token .nE5dIG_token-data {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.nE5dIG_token .nE5dIG_stats {
  text-overflow: ellipsis;
  width: 50%;
  overflow: hidden;
}

.nE5dIG_token .nE5dIG_text__level {
  text-wrap: nowrap;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
}

@media only screen and (width >= 576px) {
  .nE5dIG_token .nE5dIG_text__level {
    font-size: 18px;
    line-height: 24px;
  }
}

.nE5dIG_token .nE5dIG_wombat {
  align-items: center;
  gap: 4px;
  display: flex;
}

.nE5dIG_token .nE5dIG_icon {
  width: 12px;
  height: 12px;
}

.cPJGAa_collection {
  grid-template-areas: "cPJGAa_header cPJGAa_button"
                       "cPJGAa_collection cPJGAa_collection";
  justify-content: center;
  align-items: center;
  gap: 24px;
  display: grid;
}

.cPJGAa_collection--empty {
  grid-template-areas: "cPJGAa_header"
                       "cPJGAa_no-nfts-message";
}

@media only screen and (width >= 576px) {
  .cPJGAa_collection {
    grid-template-areas: "cPJGAa_header"
                         "cPJGAa_button"
                         "cPJGAa_collection";
  }
}

.cPJGAa_section-title {
  grid-area: cPJGAa_header;
}

.cPJGAa_empty-message {
  text-align: center;
  grid-area: "no-nfts-message";
}

.cPJGAa_button {
  grid-area: cPJGAa_button;
  justify-self: center;
}

.cPJGAa_gallery {
  grid-area: cPJGAa_collection;
  gap: 24px;
  width: calc(100vw - 20px);
  display: flex;
  overflow-x: scroll;
}

@media only screen and (width >= 576px) {
  .cPJGAa_gallery {
    padding: unset;
    overflow: unset;
    flex-wrap: wrap;
    justify-content: center;
    width: 528px;
  }
}

@media only screen and (width >= 768px) {
  .cPJGAa_gallery {
    width: 712px;
  }
}

@media only screen and (width >= 1320px) {
  .cPJGAa_gallery {
    width: 952px;
  }
}

.e7cQha_about {
  grid-template-areas: "e7cQha_info"
                       "e7cQha_gallery"
                       "e7cQha_stats";
  gap: 24px;
  display: grid;
}

@media only screen and (width >= 768px) {
  .e7cQha_about {
    flex-direction: row;
    grid-template-areas: "e7cQha_gallery e7cQha_info"
                         "e7cQha_gallery e7cQha_stats"
                         "e7cQha_gallery .";
    gap: 48px;
  }
}

@media only screen and (width >= 1320px) {
  .e7cQha_about {
    gap: 24px;
  }
}

.e7cQha_grid-container {
  grid-area: e7cQha_gallery;
  margin: auto;
  position: relative;
}

.e7cQha_image-grid {
  grid-template-rows: 260px;
  grid-template-columns: 180px;
  grid-auto-rows: 0;
  grid-auto-columns: 0;
  gap: 24px;
  width: 180px;
  height: 260px;
  display: grid;
  overflow: hidden;
}

.e7cQha_image-grid--superimposed {
  position: absolute;
  top: 0;
}

@media only screen and (width >= 576px) {
  .e7cQha_image-grid {
    grid-template-rows: repeat(2, 148px);
    grid-template-columns: repeat(4, 116px);
    width: 536px;
    height: 320px;
  }
}

@media only screen and (width >= 768px) {
  .e7cQha_image-grid {
    grid-template-rows: repeat(5, 148px);
    grid-template-columns: repeat(2, 116px);
    width: 256px;
    height: 836px;
  }
}

@media only screen and (width >= 1024px) {
  .e7cQha_image-grid {
    grid-template-rows: repeat(5, 200px);
    grid-template-columns: repeat(3, 140px);
    width: 468px;
    height: 1096px;
  }
}

@media only screen and (width >= 1320px) {
  .e7cQha_image-grid {
    grid-template-rows: repeat(5, 260px);
    grid-template-columns: repeat(3, 180px);
    width: 588px;
    height: 1396px;
  }
}

.e7cQha_image-container {
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

@media only screen and (width >= 576px) {
  .e7cQha_image-container {
    height: 128px;
  }
}

@media only screen and (width >= 1024px) {
  .e7cQha_image-container {
    height: unset;
  }
}

.e7cQha_image {
  width: 100%;
}

.e7cQha_image--superimposed {
  position: absolute;
}

.e7cQha_info {
  flex-direction: column;
  grid-area: e7cQha_info;
  gap: 16px;
  display: flex;
}

.e7cQha_info h4 {
  margin-top: 30px;
}

@media only screen and (width >= 768px) {
  .e7cQha_info {
    gap: 48px;
    width: 410px;
    margin-top: 48px;
  }
}

@media only screen and (width >= 1024px) {
  .e7cQha_info {
    gap: 72px;
    width: 474px;
    margin-top: 72px;
  }
}

@media only screen and (width >= 1320px) {
  .e7cQha_info {
    gap: 128px;
    width: 622px;
    margin-top: 128px;
  }
}

.e7cQha_info .e7cQha_top-block {
  flex-direction: column;
  gap: 16px;
  display: flex;
}

@media only screen and (width >= 1024px) {
  .e7cQha_info .e7cQha_top-block .e7cQha_text {
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 36px;
  }
}

.e7cQha_info .e7cQha_top-block .e7cQha_link-text {
  font-weight: 700px;
  font-size: 1rem;
}

.e7cQha_header--left-align {
  text-align: left !important;
}

.e7cQha_external-links {
  flex-direction: column;
  gap: 16px;
  display: flex;
}

.e7cQha_icon {
  width: 20px;
  height: 20px;
}

.e7cQha_icon--chevron {
  transform: rotate(90deg);
}

.e7cQha_stats {
  grid-area: e7cQha_stats;
  margin-top: 24px;
}

@media only screen and (width >= 576px) {
  .e7cQha_stats {
    margin-top: 48px;
  }
}

@media only screen and (width >= 768px) {
  .e7cQha_stats {
    margin-top: unset;
  }
}

.e7cQha_fade-in {
  opacity: 1;
  animation: 4s ease-in-out e7cQha_fadeIn;
}

.e7cQha_fade-out {
  opacity: 0;
  animation: 4s ease-in-out e7cQha_fadeOut;
}

@keyframes e7cQha_fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes e7cQha_fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

:root {
  --colorWhite: #fff;
  --colorTextSecondary: silver;
  --colorTextDisabled: #87878e;
  --colorHighlight: #ceaef0;
  --colorTextError: #ff5a5a;
  --colorWhiteDisabled: #ffffff4d;
  --colorBorder: #544c58;
  --colorDividerPrimary: #fff3;
  --colorLightGreyPurple: #544f75;
  --colorLightGreyPurpleTranslucent: #544f75a3;
  --colorSurface: #1d1b28;
  --colorSurfacePale: #ffffff29;
  --colorSurfacePale2: #ffffff14;
  --colorSurfaceDisabled: #3e3d48;
  --colorSurfaceDark: #24222f;
  --colorSurfaceError: #683139;
  --modalBackgroundOverlay: #000000a3;
  --colorError: #ff5a5a;
  --colorSuccess: #6fcf97;
  --colorGreen: #6fcf9752;
  --gradient-primary: linear-gradient(135deg, #d22c34 0%, #6920cb 100%);
  --baseFont: "Montserrat", sans-serif;
}

._74eevq_table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  margin: 16px auto 10px;
}

@media only screen and (width >= 768px) {
  ._74eevq_table {
    margin-top: 24px;
  }
}

@media only screen and (width >= 1024px) {
  ._74eevq_table {
    margin-top: 36px;
  }
}

@media only screen and (width >= 1320px) {
  ._74eevq_table {
    margin-top: 48px;
  }
}

._74eevq_table tr {
  width: 100%;
}

._74eevq_table tr:not(:last-child) {
  border-bottom: .1rem solid var(--colorWhiteDisabled);
}

._74eevq_table th {
  text-align: left;
  font-size: 12px;
  font-weight: 700;
}

@media only screen and (width >= 768px) {
  ._74eevq_table th {
    font-size: 14px;
    font-weight: 600;
  }
}

@media only screen and (width >= 1024px) {
  ._74eevq_table th {
    font-size: 16px;
  }
}

._74eevq_table th, ._74eevq_table td {
  padding-top: 24px;
  padding-bottom: 24px;
}

._74eevq_table td {
  color: var(--colorTextSecondary);
  text-align: left;
  font-size: 12px;
  font-weight: 400;
}

@media only screen and (width >= 768px) {
  ._74eevq_table td {
    font-size: 14px;
  }
}

@media only screen and (width >= 1024px) {
  ._74eevq_table td {
    font-size: 16px;
  }
}

._74eevq_table--horizontal {
  text-align: left;
  display: none;
}

@media only screen and (width >= 768px) {
  ._74eevq_table--horizontal {
    justify-content: center;
    display: table;
  }
}

._74eevq_table--horizontal th {
  width: 150px;
}

@media only screen and (width >= 1024px) {
  ._74eevq_table--horizontal th {
    width: 175px;
  }
}

@media only screen and (width >= 768px) {
  ._74eevq_table--vertical {
    display: none;
  }
}

:root {
  --colorWhite: #fff;
  --colorTextSecondary: silver;
  --colorTextDisabled: #87878e;
  --colorHighlight: #ceaef0;
  --colorTextError: #ff5a5a;
  --colorWhiteDisabled: #ffffff4d;
  --colorBorder: #544c58;
  --colorDividerPrimary: #fff3;
  --colorLightGreyPurple: #544f75;
  --colorLightGreyPurpleTranslucent: #544f75a3;
  --colorSurface: #1d1b28;
  --colorSurfacePale: #ffffff29;
  --colorSurfacePale2: #ffffff14;
  --colorSurfaceDisabled: #3e3d48;
  --colorSurfaceDark: #24222f;
  --colorSurfaceError: #683139;
  --modalBackgroundOverlay: #000000a3;
  --colorError: #ff5a5a;
  --colorSuccess: #6fcf97;
  --colorGreen: #6fcf9752;
  --gradient-primary: linear-gradient(135deg, #d22c34 0%, #6920cb 100%);
  --baseFont: "Montserrat", sans-serif;
}

.maQ0eq_faq-list {
  flex-direction: column;
  display: flex;
}

.maQ0eq_faq-item {
  padding: 24px 0;
}

.maQ0eq_faq-item:not(:last-child) {
  border-bottom: .1rem solid var(--colorWhiteDisabled);
}

.maQ0eq_question {
  text-align: left;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  display: flex;
}

@media only screen and (width >= 1024px) {
  .maQ0eq_question {
    font-size: 18px;
    line-height: 24px;
  }
}

.maQ0eq_answer {
  padding-top: 16px;
  font-size: 14px;
  line-height: 21px;
}

@media only screen and (width >= 1024px) {
  .maQ0eq_answer {
    font-size: 16px;
    line-height: 24px;
  }
}

.maQ0eq_chevron--down {
  transform: rotate(180deg);
}

.bUhOoa_socials {
  gap: 12px;
  display: flex;
}

@media only screen and (width >= 768px) {
  .bUhOoa_socials {
    gap: 24px;
  }
}

.bUhOoa_icon {
  width: 36px;
  height: 36px;
}

:root {
  --colorWhite: #fff;
  --colorTextSecondary: silver;
  --colorTextDisabled: #87878e;
  --colorHighlight: #ceaef0;
  --colorTextError: #ff5a5a;
  --colorWhiteDisabled: #ffffff4d;
  --colorBorder: #544c58;
  --colorDividerPrimary: #fff3;
  --colorLightGreyPurple: #544f75;
  --colorLightGreyPurpleTranslucent: #544f75a3;
  --colorSurface: #1d1b28;
  --colorSurfacePale: #ffffff29;
  --colorSurfacePale2: #ffffff14;
  --colorSurfaceDisabled: #3e3d48;
  --colorSurfaceDark: #24222f;
  --colorSurfaceError: #683139;
  --modalBackgroundOverlay: #000000a3;
  --colorError: #ff5a5a;
  --colorSuccess: #6fcf97;
  --colorGreen: #6fcf9752;
  --gradient-primary: linear-gradient(135deg, #d22c34 0%, #6920cb 100%);
  --baseFont: "Montserrat", sans-serif;
}

.ikdfEG_legals {
  gap: 8px;
  display: flex;
}

.ikdfEG_legals p {
  color: var(--colorTextSecondary);
  font-size: 12px;
  text-decoration: underline;
}

.ikdfEG_link:not(:last-child) {
  border-right: .2rem dotted var(--colorWhiteDisabled);
  padding-right: 8px;
}

.RQieiW_footer {
  flex-direction: column;
  gap: 12px;
  width: 100%;
  display: flex;
}

@media only screen and (width >= 768px) {
  .RQieiW_footer {
    align-items: center;
    width: 315px;
  }
}

.kMuioa_testing-panel {
  z-index: 100;
  background-color: #5f9ea0;
  width: 400px;
  padding: 8px;
  position: absolute;
  top: 30px;
  right: 0;
}

.kMuioa_top-bar {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.kMuioa_top-bar button {
  cursor: pointer;
  background-color: #00008b;
  border-radius: 20%;
  margin: 2px;
  padding: 5px;
}

:root {
  --colorWhite: #fff;
  --colorTextSecondary: silver;
  --colorTextDisabled: #87878e;
  --colorHighlight: #ceaef0;
  --colorTextError: #ff5a5a;
  --colorWhiteDisabled: #ffffff4d;
  --colorBorder: #544c58;
  --colorDividerPrimary: #fff3;
  --colorLightGreyPurple: #544f75;
  --colorLightGreyPurpleTranslucent: #544f75a3;
  --colorSurface: #1d1b28;
  --colorSurfacePale: #ffffff29;
  --colorSurfacePale2: #ffffff14;
  --colorSurfaceDisabled: #3e3d48;
  --colorSurfaceDark: #24222f;
  --colorSurfaceError: #683139;
  --modalBackgroundOverlay: #000000a3;
  --colorError: #ff5a5a;
  --colorSuccess: #6fcf97;
  --colorGreen: #6fcf9752;
  --gradient-primary: linear-gradient(135deg, #d22c34 0%, #6920cb 100%);
  --baseFont: "Montserrat", sans-serif;
}

html {
  scroll-behavior: smooth;
}

section {
  scroll-margin-top: 100px;
}

@media only screen and (width >= 345px) {
  section {
    width: 328px;
  }
}

@media only screen and (width >= 576px) {
  section {
    gap: 72px;
    width: 528px;
  }
}

@media only screen and (width >= 768px) {
  section {
    gap: 48px;
    width: 720px;
    margin: 0 auto;
  }
}

@media only screen and (width >= 1024px) {
  section {
    gap: 72px;
    width: 972px;
    margin: 0 auto;
  }
}

@media only screen and (width >= 1320px) {
  section {
    gap: 128px;
    width: 1272px;
    margin: 0 auto;
  }
}

body {
  box-sizing: border-box;
  background-color: var(--colorSurface);
  color: var(--colorWhite);
  font-family: var(--baseFont);
  flex-direction: column;
  min-height: 100vh;
  margin: 0 0 48px;
  padding: 0;
  display: flex;
  position: relative;
  overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  padding: 0;
}

h2 {
  text-align: center;
  letter-spacing: 0;
  text-align: center;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
}

@media only screen and (width >= 576px) {
  h2 {
    letter-spacing: 0;
    text-align: center;
    font-family: Montserrat;
    font-size: 28px;
    font-weight: 700;
    line-height: 42px;
  }
}

@media only screen and (width >= 1024px) {
  h2 {
    letter-spacing: 0;
    text-align: center;
    font-family: Montserrat;
    font-size: 36px;
    font-weight: 700;
    line-height: 54px;
  }
}

@media only screen and (width >= 1320px) {
  h2 {
    letter-spacing: 0;
    text-align: center;
    font-family: Montserrat;
    font-size: 48px;
    font-weight: 700;
    line-height: 72px;
  }
}

h3 {
  color: var(--colorTextDisabled);
  font-size: 28px;
  font-weight: 700;
  line-height: 42px;
}

@media only screen and (width >= 576px) {
  h3 {
    font-size: 36px;
    line-height: 54px;
  }
}

@media only screen and (width >= 1024px) {
  h3 {
    font-size: 48px;
    line-height: 72px;
  }
}

@media only screen and (width >= 1320px) {
  h3 {
    font-size: 72px;
    line-height: 96px;
  }
}

h4 {
  text-align: left;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

@media only screen and (width >= 576px) {
  h4 {
    font-size: 20px;
    line-height: 28px;
  }
}

@media only screen and (width >= 1024px) {
  h4 {
    font-size: 20px;
    line-height: 28px;
  }
}

@media only screen and (width >= 1320px) {
  h4 {
    font-size: 28px;
    font-weight: 700;
    line-height: 42px;
  }
}

button, input[type="submit"], input[type="reset"] {
  color: inherit;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  background: none;
  border: none;
  padding: 0;
}

input {
  color: inherit;
  font: inherit;
  background: none;
  border: none;
  outline: none;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

@media only screen and (width >= 345px) {
  .default-section {
    width: 328px;
  }
}

@media only screen and (width >= 576px) {
  .default-section {
    width: 528px;
  }
}

@media only screen and (width >= 768px) {
  .default-section {
    width: 476px;
  }
}

@media only screen and (width >= 1024px) {
  .default-section {
    width: 640px;
  }
}

@media only screen and (width >= 1320px) {
  .default-section {
    width: 622px;
  }
}

.content {
  flex-direction: column;
  align-self: center;
  align-items: center;
  gap: 72px;
  margin-bottom: 24px;
  display: flex;
}

@media only screen and (width >= 768px) {
  .content {
    margin-bottom: 48px;
  }
}

@media only screen and (width >= 1024px) {
  .content {
    margin-bottom: 72px;
  }
}

@media only screen and (width >= 1320px) {
  .content {
    margin-bottom: 80px;
  }
}

.label--medium {
  text-align: left;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
}

@media only screen and (width >= 576px) {
  .label--medium {
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
  }
}

.label--large {
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
}

@media only screen and (width >= 1320px) {
  .label--large {
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
  }
}

/*# sourceMappingURL=index.6ebed212.css.map */
