@import './assets/styles/variables';
@import './assets/styles/medias';
@import './assets/styles/typography';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;600;700&display=swap');

html {
  scroll-behavior: smooth;
}

section {
  scroll-margin-top: 100px;
  
  @include mobile-sm {
    width: 328px;
  }

  @include mobile-md {
    width: 528px;
    gap: 72px;
  }

  @include tablet {
    width: 720px;
    gap: 48px;
    margin: 0 auto;
  }

  @include desktop {
    width: 972px;
    gap: 72px;
    margin: 0 auto;
  }

  @include desktop-xl {
    width: 1272px;
    gap: 128px;
    margin: 0 auto;
  }
}

body {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0 0 48px 0;
  padding: 0;
  box-sizing: border-box;
  background-color: var(--colorSurface);
  color: var(--colorWhite);
  font-family: var(--baseFont);
  overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  padding: 0;
}

h2 {
  text-align: center;
  @include section-title(24px, 30px);

  @include mobile-md {
    @include section-title(28px, 42px)
  }

  @include desktop {
    @include section-title(36px, 54px)
  }

  @include desktop-xl {
    @include section-title(48px, 72px)
  }
}

h3 {
  color: var(--colorTextDisabled);
  font-weight: 700;
  font-size: 28px;
  line-height: 42px;
  
  @include mobile-md {
    font-size: 36px;
    line-height: 54px;
  }

  @include desktop {
    font-size: 48px;
    line-height: 72px;
  }

  @include desktop-xl {
    font-size: 72px;
    line-height: 96px;
  }
}

h4 {
  text-align: left;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;

  @include mobile-md {
    font-size: 20px;
    line-height: 28px;

  }

  @include desktop {
    font-size: 20px;
    line-height: 28px;
  }

  @include desktop-xl {
    font-weight: 700;
    font-size: 28px;
    line-height: 42px;
  }
}

// unset default button styles
button, input[type="submit"], input[type="reset"] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

input {
  background: none;
  border: none;
	color: inherit;
	font: inherit;
  outline: none;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: inherit;
}

.default-section {
  @include mobile-sm {
    width: 328px;
  }

  @include mobile-md {
    width: 528px;
  }

  @include tablet {
    width: 476px;
  }

  @include desktop {
    width: 640px;
  }

  @include desktop-xl {
    width: 622px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  gap: 72px;
  margin-bottom: 24px;

  @include tablet {
    margin-bottom: 48px;
  }

  @include desktop {
    margin-bottom: 72px;
  }

  @include desktop-xl {
    margin-bottom: 80px;
  }
}

.label {
  &--medium {
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    text-align: left;

    @include mobile-md {
      font-size: 14px;
      font-weight: 600;
      line-height: 17px;
    }
  }

  &--large {
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    text-align: center;

    @include desktop-xl {
      font-size: 16px;
      font-weight: 700;
      line-height: 19px;
    }
  }
}
